import { ChatbotUiState, UiState, WebUiState } from "@/components/enums/UiState";
import { SceneChatMessagesDynamicZone } from "@/pages/stele/Chat";
import { observable, action, makeObservable } from "mobx";
import {
    Age,
    CancelCall,
    EndOfCallOld,
    EndOfCallRegular,
    Hint,
    Landing,
    Loading,
    Global,
    PostProcessingEntity,
    Mask,
    Tutorial,
    Start,
    ComingSoon,
    PageNotFound,
    ChatStele,
} from "types/strapi";
import { trackPage } from "utils/track";

export class Store {
    locale: string | undefined = undefined;
    locales: string[] = [];
    localeNames: string[] = [];
    postProcessings: PostProcessingEntity[] = [];
    landingLocalized: Landing | undefined = undefined;
    ageLocalized: Age | undefined = undefined;
    cancelCallLocalized: CancelCall | undefined = undefined;
    endOfCallOldLocalized: EndOfCallOld | undefined = undefined;
    endOfCallRegularLocalized: EndOfCallRegular | undefined = undefined;
    hintLocalized: Hint | undefined = undefined;
    loadingLocalized: Loading | undefined = undefined;
    maskLocalized: Mask | undefined = undefined;
    tutorialLocalized: Tutorial | undefined = undefined;
    globalLocalized: Global | undefined = undefined;
    startLocalized: Start | undefined = undefined;
    comingSoonLocalized: ComingSoon | undefined = undefined;
    pageNotFoundLocalized: PageNotFound | undefined = undefined;
    chatSteleLocalized: ChatStele | undefined = undefined;

    renderContent: UiState = UiState.LANDING;
    renderWebContent: WebUiState = WebUiState.NONE;
    renderChatbotContent: ChatbotUiState = ChatbotUiState.AGE;

    imageAgedUrl: string | undefined = undefined;
    captureFace: boolean = false;

    buttonsPressed: boolean[] = new Array(501).fill(false);
    chatMessagesScene: SceneChatMessagesDynamicZone[] | undefined = undefined;
    chatIndexCurrent: number = 0;
    chatIndexDelayedCurrent: number | undefined = undefined;
    chatIndexTypedCurrent: number | undefined = undefined;

    startCountdown: boolean = false;

    constructor() {
        makeObservable(this, {
            locale: observable,
            locales: observable,
            localeNames: observable,
            postProcessings: observable,
            imageAgedUrl: observable,
            captureFace: observable,
            renderContent: observable,
            chatMessagesScene: observable,
            chatIndexCurrent: observable,
            chatIndexDelayedCurrent: observable,
            chatIndexTypedCurrent: observable,
            buttonsPressed: observable,
            startCountdown: observable,
            landingLocalized: observable,
            ageLocalized: observable,
            cancelCallLocalized: observable,
            endOfCallOldLocalized: observable,
            endOfCallRegularLocalized: observable,
            hintLocalized: observable,
            loadingLocalized: observable,
            maskLocalized: observable,
            tutorialLocalized: observable,
            globalLocalized: observable,
            startLocalized: observable,
            renderWebContent: observable,
            renderChatbotContent: observable,
            comingSoonLocalized: observable,
            pageNotFoundLocalized: observable,
            chatSteleLocalized: observable,
            updateLocale: action,
            updateLocales: action,
            updateLocaleNames: action,
            updatePostProcessings: action,
            updateImageAgedUrl: action,
            updateCaptureFace: action,
            updateRenderContent: action,
            updateButtonById: action,
            updateChatMessagesScene: action,
            getButtonPressedById: action,
            updateChatIndexCurrent: action,
            updateChatIndexDelayedCurrent: action,
            updateChatIndexTypedCurrent: action,
            updateStartCountdown: action,
            updateLandingLocalized: action,
            updateAgeLocalized: action,
            updateCancelCallLocalized: action,
            updateEndOfCallOldLocalized: action,
            updateEndOfCallRegularLocalized: action,
            updateHintLocalized: action,
            updateLoadingLocalized: action,
            updateMaskLocalized: action,
            updateTutorialLocalized: action,
            updateGlobalLocalized: action,
            updateStartLocalized: action,
            updateRenderWebContent: action,
            updateRenderChatbotContent: action,
            updateComingSoonLocalized: action,
            updatePageNotFoundLocalized: action,
            updateChatSteleLocalized: action,
        });

        this.updateLocale = this.updateLocale.bind(this);
        this.updateLocales = this.updateLocales.bind(this);
        this.updateLocaleNames = this.updateLocaleNames.bind(this);
        this.updatePostProcessings = this.updatePostProcessings.bind(this);
        this.updateImageAgedUrl = this.updateImageAgedUrl.bind(this);
        this.updateCaptureFace = this.updateCaptureFace.bind(this);
        this.updateRenderContent = this.updateRenderContent.bind(this);
        this.updateButtonById = this.updateButtonById.bind(this);
        this.updateChatMessagesScene = this.updateChatMessagesScene.bind(this);
        this.getButtonPressedById = this.getButtonPressedById.bind(this);
        this.updateChatIndexCurrent = this.updateChatIndexCurrent.bind(this);
        this.updateChatIndexDelayedCurrent = this.updateChatIndexDelayedCurrent.bind(this);
        this.updateChatIndexTypedCurrent = this.updateChatIndexTypedCurrent.bind(this);
        this.updateStartCountdown = this.updateStartCountdown.bind(this);
        this.updateLandingLocalized = this.updateLandingLocalized.bind(this);
        this.updateAgeLocalized = this.updateAgeLocalized.bind(this);
        this.updateCancelCallLocalized = this.updateCancelCallLocalized.bind(this);
        this.updateEndOfCallOldLocalized = this.updateEndOfCallOldLocalized.bind(this);
        this.updateEndOfCallRegularLocalized = this.updateEndOfCallRegularLocalized.bind(this);
        this.updateHintLocalized = this.updateHintLocalized.bind(this);
        this.updateLoadingLocalized = this.updateLoadingLocalized.bind(this);
        this.updateMaskLocalized = this.updateMaskLocalized.bind(this);
        this.updateTutorialLocalized = this.updateTutorialLocalized.bind(this);
        this.updateGlobalLocalized = this.updateGlobalLocalized.bind(this);
        this.updateStartLocalized = this.updateStartLocalized.bind(this);
        this.updateRenderWebContent = this.updateRenderWebContent.bind(this);
        this.updateRenderChatbotContent = this.updateRenderChatbotContent.bind(this);
        this.updateComingSoonLocalized = this.updateComingSoonLocalized.bind(this);
        this.updatePageNotFoundLocalized = this.updatePageNotFoundLocalized.bind(this);
        this.updateChatSteleLocalized = this.updateChatSteleLocalized.bind(this);
    }

    updateLocale(newValue: string, isInitializer?: boolean): void {
        if (isInitializer) {
            trackPage("Loading-Screen", "Start");
        }

        this.locale = newValue;
    }

    updateLocales(newValue: string[]): void {
        this.locales = newValue;
    }

    updateLocaleNames(newValue: string[]): void {
        this.localeNames = newValue;
    }

    updatePostProcessings(newValue: PostProcessingEntity[]): void {
        this.postProcessings = newValue;
    }

    updateImageAgedUrl(newValue: string): void {
        this.imageAgedUrl = newValue;
    }

    updateCaptureFace(newValue: boolean): void {
        this.captureFace = newValue;
    }

    updateRenderContent(newValue: UiState): void {
        this.renderContent = newValue;
    }

    updateRenderWebContent(newValue: WebUiState): void {
        this.renderWebContent = newValue;
    }

    updateRenderChatbotContent(newValue: ChatbotUiState): void {
        this.renderChatbotContent = newValue;
    }

    updateButtonById(buttonId: number, pressed: boolean): void {
        if (buttonId >= 500) {
            throw Error("Button Id is out of date");
        }

        this.buttonsPressed[buttonId] = pressed;
    }

    updateChatMessagesScene(newValue: SceneChatMessagesDynamicZone[]): void {
        this.chatMessagesScene = newValue;
    }
    getButtonPressedById(buttonId: number): boolean {
        if (buttonId >= 500) {
            throw Error("Button Id is out of date");
        }

        return this.buttonsPressed[buttonId];
    }
    updateChatIndexCurrent(newValue: number): void {
        this.chatIndexCurrent = newValue;
    }
    updateChatIndexDelayedCurrent(newValue: number | undefined): void {
        this.chatIndexDelayedCurrent = newValue;
    }
    updateChatIndexTypedCurrent(newValue: number | undefined): void {
        this.chatIndexTypedCurrent = newValue;
    }
    updateStartCountdown(newValue: boolean): void {
        this.startCountdown = newValue;
    }
    updateLandingLocalized(newValue: Landing): void {
        this.landingLocalized = newValue;
    }
    updateAgeLocalized(newValue: Age): void {
        this.ageLocalized = newValue;
    }
    updateCancelCallLocalized(newValue: CancelCall): void {
        this.cancelCallLocalized = newValue;
    }
    updateEndOfCallOldLocalized(newValue: EndOfCallOld): void {
        this.endOfCallOldLocalized = newValue;
    }
    updateEndOfCallRegularLocalized(newValue: EndOfCallRegular): void {
        this.endOfCallRegularLocalized = newValue;
    }
    updateHintLocalized(newValue: Hint): void {
        this.hintLocalized = newValue;
    }
    updateLoadingLocalized(newValue: Loading): void {
        this.loadingLocalized = newValue;
    }
    updateMaskLocalized(newValue: Mask): void {
        this.maskLocalized = newValue;
    }
    updateTutorialLocalized(newValue: Tutorial): void {
        this.tutorialLocalized = newValue;
    }
    updateGlobalLocalized(newValue: Global): void {
        this.globalLocalized = newValue;
    }
    updateStartLocalized(newValue: Start): void {
        this.startLocalized = newValue;
    }
    updateComingSoonLocalized(newValue: ComingSoon): void {
        this.comingSoonLocalized = newValue;
    }
    updatePageNotFoundLocalized(newValue: PageNotFound): void {
        this.pageNotFoundLocalized = newValue;
    }
    updateChatSteleLocalized(newValue: ChatStele): void {
        this.chatSteleLocalized = newValue;
    }
}

const store = new Store();
export default store;

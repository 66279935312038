

import React, { useEffect } from 'react';
import markerSDK from '@marker.io/browser';

export default function MarkerComponent() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MARKER_IO) {
      console.log('Initializing Marker.IO')
      markerSDK.loadWidget({
        project: process.env.NEXT_PUBLIC_MARKER_IO,
      });
    } else {
      console.log('Marker IO Project Id not provided')
    }
  }, []);

  return null;
}
import store from "stores/store";

declare var ste: any;

export const trackPage = async (path: String, name: String) => {
    const id = "everyday-world";
    const type = "feature";
    const format = "html5";
    const lang = store.locale;

    const pageData = {
        id,
        type,
        format,
        lang,
        path,
        name,
    };

    console.log("Tracking locale: " + lang);
    // if (typeof ste === "object") {
    //     console.log("Trying to send Page Tracking Data");
    //     ste.feature.page(pageData);
    // }
};

export const trackLink = async (path: String, url: String, name: String) => {
    const id = "everyday-world";
    const type = "feature";
    const format = "html5";
    const lang = store.locale;

    const linkData = {
        id,
        type,
        format,
        lang,
        path,
        url,
        name,
    };

    console.log("Tracking locale: " + lang);
    // if (typeof ste === "object") {
    //     console.log("Trying to send Link Tracking Data");
    //     ste.feature.link(linkData);
    // }
};

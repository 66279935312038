export enum UiState {
    NONE = "none",
    LANDING = "landingpage",
    HINT = "hintpage",
    TUTORIAL = "tutorialpage",
    CALL = "callpage",
    MASK = "maskpage",
    CANCELCALL = "cancelcallpage",
    CHAT = "chatpage",
    ENDOFCALL = "endofcallpage",
    LOADING = "loadingpage",
    AGE = "agepage",
    END_ONE = "endOne",
    END_TWO = "endTwo",
}

export enum WebUiState {
    NONE = "none",
    LANDING = "landing",
    HINT = "hint",
    TUTORIAL = "tutorial",
    MASK = "mask",
    CANCELCALL = "cancelCall",
    CHAT = "chatBot",
    ENDOFCALL = "endOfCall",
    LOADING = "loading",
    AGE = "age",
}

export enum ChatbotUiState {
    NONE = "none",
    CHATBOT = "chatbot",
    AGE = "age",
    HINT = "hint",
    ENDOFCALL = "endOfCall",
    CANCELCALL = "cancelCall",
    TUTORIAL = "tutorial",
}

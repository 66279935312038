/* eslint-disable @next/next/no-sync-scripts */
import "@/styles/globals.css";
import "@/styles/font.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import { Provider } from "mobx-react";
import store from "stores/store";
import MarkerComponent from "@/feedback/MarkerComponent";
// import * as Sentry from "@sentry/browser";

// Sentry.init({
//     dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost"],
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        // Check if the current URL ends with "index.html"
        if (window.location.pathname.endsWith("/index.html")) {
            // Remove "index.html" from the URL and redirect
            const newURL = window.location.pathname.replace("/index.html", "");
            window.location.href = newURL;
        }
    }, []);

    return (
        <>
            <Head>
                <link rel="shortcut icon" href={"./favicon.svg"} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
            </Head>

            <Provider store={store}>
                <Component {...pageProps} />
                <MarkerComponent />
            </Provider>
        </>
    );
}
